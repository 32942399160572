/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

/* stylelint-disable declaration-no-important */

.ProductMainViewOptions {
    @include desktop {
        width: calc(100vw - #{$leftMenuWidth});
    }

    @include mobile {
        min-height: calc(100vh - var(--header-height));
    }

    .Tabs {
        &-List {
            @include desktop {
                position: unset;
                inset-block-start: 0;
                border-bottom: none;
                width: 40%;
                margin: 0 auto;
            }

            @include mobile {
                inset-block-start: 115px;
            }
        }

        &-Wrapper {
            @include desktop {
                padding: 0;
                height: 100%;
            }

            @include mobile {
                padding: 15px 0 0 0;
            }
        }
    }

    .NoRelatedItems {
        margin: 100px 0 0 0;
        width: 100%;
        text-align: center;
        font-size: 22px;
        font-weight: 700;
    }

    .ProductLinks {
        @include mobile {
            margin-block-start: 68px;
            padding: 0 16px;
        }

        &-Wrapper {
            @include desktop {
                padding: 24px 44px;
            }

            @include mobile {
                padding: 0;
            }
        }

        &-List {
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); // 300px / 2

            @include desktop {
                display: flex;
                flex-wrap: wrap;
            }
        }

        li.ProductCard {
            display: flex;
            flex-direction: column;
            cursor: pointer;

            &_isAdded, &:hover {
                @include desktop {
                    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .1),
                        0 6px 16px rgba(0, 0, 0, .1),
                        0 9px 28px 8px rgba(0, 0, 0, .1);
                    z-index: 4;

                    .ProductCard-VisibleOnHover {
                        background: var(--product-card-background);
                        width: inherit;
                        opacity: 1;
                        box-shadow: 0 3px 6px rgba(0, 0, 0, .1),
                            0 6px 16px rgba(0, 0, 0, .1),
                            0 9px 28px 8px rgba(0, 0, 0, .1);
                        z-index: -1;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        flex-grow: 1;
                        padding-block-start: 0;
                        padding-block-end: 10px;
                        padding-inline: 15px;
                        position: absolute;
                        inset-block-start: 100%;
                    }

                    .RelatedProductCard {
                        &-Content {
                            .CardPriceWrapper {
                                span {
                                    color: var(--secondary-orange-color);
                                }
                            }
                        }
                    }
                }
            }

            &_isAdded {
                @include mobile {
                    .RelatedProductCard {
                        &-Content {
                            .CardPriceWrapper {
                                span {
                                    color: var(--secondary-orange-color);
                                }
                            }
                        }
                    }
                }

                .RelatedProductCard-Figure {
                    .Field [type='checkbox']:checked
                    + .input-control .RadioIcon {
                        --checkmark-color: white;
                    }

                    .input-control {
                        border-color: var(--secondary-orange-color);
                        background-color: var(--secondary-orange-color);
                    }
                }
            }

            @include desktop {
                width: 230px;
                padding: 16px 12px;
            }
        }
    }
}
