/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

$contentHeight: calc(100vh - var(--header-height) - var(--breadcrumbs-height));

.ProductPage {
    margin-inline: 0;
    background-color: var(--surface-color);
    padding-block-end: 0;
    padding-block-start: var(--breadcrumbs-height);
    overflow: hidden;

    @include mobile {
        margin: 0;
        padding-block-start: calc(var(--breadcrumbs-height) - 3px);
    }

    &-Wrapper {
        margin: 0;
        padding: 0;
        max-width: none;
        column-gap: 0;

        @include desktop {
            display: flex;
            height: 100%;
        }

        @include mobile {
            display: grid;
            background-color: white;
        }
    }

    > section {
        @include desktop {
            height: $contentHeight;
        }
    }
}
