@mixin not-available {
    content: '';
    position: absolute;
    width: 1px;
    height: 34px;
    transform-origin: 50% 50%;
    inset-inline-start: 17px;
    inset-block-start: 1px;
    transform: rotate(45deg);
    box-shadow: none;
    border-radius: 0;
    opacity: 1;
}
