/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

/* stylelint-disable font-family-no-missing-generic-family-keyword */

.ProductMonthlyPrice {
    &-PriceWrapper {
        display: flex;

        .ToolTipButton {
            &-Icon {
                @include desktop {
                    margin-inline-start: 5px;
                }

                @include mobile {
                    margin-inline-start: 8px;
                }
            }

            @include desktop {
                .Popup {
                    &_isVisible {
                        inset-inline-start: 5px;
                    }
                }
            }
        }
    }
}

.MonthlyPaymentTooltip {
    &-Wrapper {
        height: 75px;
        background: #FFFFFF;

        @include desktop {
            padding: 8px 8px 8px 8px;
            width: 270px;
        }

        @include mobile {
            width: 100%;
        }
    }

    &-Item {
        display: flex;
        justify-content: space-between;

        @include desktop {
            margin-block-end: 5px;
        }

        @include mobile {
            margin-block-end: 15px;
        }
    }

    &-Label {
        font-family: $font-HyundaiSansTextKR;
        font-style: normal;
        font-weight: 400;
        
        @include desktop {
            font-size: 12px;
            line-height: 18px;
        }

        @include mobile {
            font-size: 16px;
            line-height: 21px;
        }

        &.bold {
            font-weight: 600;
        }
    }

    &-Value {
        font-family: $font-HyundaiSansTextKR;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;

        @include desktop {
            font-size: 12px;
            line-height: 18px;
        }

        @include mobile {
            font-size: 16px;
            line-height: 21px;
        }
    }
}
