/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

$borderStyle: 1px solid #D9D9D9;
$compareIcon: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.00098 6.50015H12.001' stroke='black' stroke-width='1.5'/%3E%3Cpath d='M2.00098 14.5002H6.00098' stroke='black' stroke-width='1.5'/%3E%3Cpath d='M11 14.5002H18' stroke='black' stroke-width='1.5'/%3E%3Cpath d='M14.5 8.75C16.0188 8.75 17.25 7.51878 17.25 6C17.25 4.48122 16.0188 3.25 14.5 3.25C12.9812 3.25 11.75 4.48122 11.75 6C11.75 7.51878 12.9812 8.75 14.5 8.75Z' stroke='black' stroke-width='1.5'/%3E%3Cpath d='M8.5 16.75C10.0188 16.75 11.25 15.5188 11.25 14C11.25 12.4812 10.0188 11.25 8.5 11.25C6.98122 11.25 5.75 12.4812 5.75 14C5.75 15.5188 6.98122 16.75 8.5 16.75Z' stroke='black' stroke-width='1.5'/%3E%3C/svg%3E%0A");

.ProductConfigurableAttributes {
    div:not(:last-child) {
        margin-block-end: 48px;

        @include mobile {
            margin-block-end: 36px;
        }
    }

    &-Wrapper {
        display: flex;
        flex-direction: column;
        background-color: white;


        @include desktop {
            width: $leftMenuWidth;
            border-right: 1px solid #D9D9D9;
        }

        .ProductGallery {
            @include mobile {
                max-height: 250px;
            }
        }

        .InformationalText {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: var(--disabled-color);
            text-align: center;
            margin-block-end: 30px;
        }
    }
}

.ProductStepPlaceholder {
    margin-block-start: 30px;
}

.ProductSteps {
    &-CompareTrimsWrapper {
        width: 100%;
        height: 60px;
        background-color: #f4f4f4;

        @include mobile {
            margin-block-end: 17px;
        }
    }

    &-CompareButton {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        @include desktop {
            border: $borderStyle
        }

        @include ultra-narrow-desktop {
            flex-direction: column;
        }

        @include mobile {
            border-top: $borderStyle;
            border-bottom: $borderStyle;
        }
    }

    &-CompareButtonText {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        margin-inline-start: 4px;

        @include ultra-narrow-desktop {
            margin-inline-start: 0;
            margin-block-start: 4px;
        }
    }

    &-CompareIcon {
        background-image: $compareIcon;
        width: 20px;
        height: 20px;
    }
}

