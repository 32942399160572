/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.GalleryIcons {
    @include small-mobile {
        border-block-end: 1px dashed var(--disabled-color);
    }
}

.ProductGalleryIcons {
    display: flex;
    justify-content: center;

    @include small-mobile {
        transform: scale(0.8);
    }

    @include mobile {
        padding: 0 0 42px 0;
        border-block-end: 1px dashed var(--disabled-color);
    }

    @include small-mobile {
        border-block-end: none;
    }

    &-Item {
        display: flex;
        align-items: center;

        @include desktop {
            padding: 0;
            padding-inline-start: 32px;
        }

        @include mobile {
            padding: 0;
            padding-inline-start: 19px;
        }

        &:not(:last-child) {
            .Description {
                padding-inline-end: 16px;
                border-inline-end: 1px dashed #999;

                @include desktop {
                    padding-inline-end: 32px;
                }
            }
        }

        &:first-child {
            padding-inline-start: 0;
        }


        .Icon {
            @include desktop {
                margin-inline-end: 10px;
            }

            @include mobile {
                margin-inline-end: 10px;
            }

            .Image {
                width: 30px;
                height: 30px;
            }
        }

        .Description {
            font-size: 18px;
            line-height: 24px;
            font-family: $font-HyundaiSansTextKR;
            font-weight: 600;

            @include desktop {
                font-size: 26px;
                line-height: 26px;
            }
        }
    }
}
