/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.ProductCard {
    &-Content {
        padding: 24px 20px 32px;
    }

    &-FigureReview {
        padding: 20px 20px 0;
    }

    &-Info {
        height: 52px;
    }

    &-Name {
        font-size: 30px;
        font-weight: 700;
        line-height: 32px;
        font-family: 'HyundaiSansHeadKR', sans-serif;
        text-transform: uppercase;
        margin: 0;
        color: var(--body-content-color);
        padding-block-end: 0;
    }

    &-Brand {
        margin: 0;
        font-size: 14px;
        line-height: 20px;
        opacity: 1;
        color: var(--disabled-color);
    }

    &-Picture {
        padding-block-end: 60%;
        height: 0;
    }

    &-PriceWrapper {
        margin-block-start: 16px;
        border-block-start: 1px solid var(--stroke-color);
        padding-block-start: 12px;
        padding-block-end: 24px;
    }

    &-ShortDescription {
        overflow: hidden;
    }

    &-ShortDescription * {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: var(--disabled-color);
    }

    &-OutOfStock {
        height: 60px;
    }

    .ProductPrice {
        &-Price {
            align-items: center;
        }

        &-PriceBadge {
            margin: 0;
            margin-inline-end: 8px;
            font-size: 14px;
            line-height: 20px;
            color: var(--disabled-color);
        }

        &-PriceValue {
            color: var(--body-content-color);
            margin: 0;
            font-size: 16px;
            line-height: 24px;
        }

        &-BundleTo,
        &-TierPrice {
            @include desktop {
                position: absolute;
            }
        }
    }


    .Button {
        font-family: 'HyundaiSansHeadKR', sans-serif;
        line-height: 28px;
    }

    &:hover {
        .Button {
            &_isHollow {
                @include desktop {
                    background-color: var(--primary-base-color);
                    color: var(--color-white);
                }

                @include desktop {
                    .ChevronIcon {
                        fill: var(--input-background-color);
                    }
                }
            }
        }
    }

    .TextPlaceholder,
    .Image_isPlaceholder {
        opacity: 1;
        animation: var(--placeholder-animation);
    }
}
