/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.ProductFilteredConfigurableAttributes {
    .ProductConfigurableAttributes {
        &-SwatchList {
            .ProductAttributeValue {
                cursor: default;
                
                &-Color {
                    pointer-events: none;
                    cursor: default;
                    
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}
