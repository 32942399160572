/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

$tabHeaderHeight: 84px;
$borderStyle: 1px solid #D9D9D9;
$marginEndMobile: 20px;

.ProductStepsTab {
    font-family: $font-HyundaiSansTextKR;

    &-TabHeader {
        margin-inline-start: 0;
        margin-block-end: 0;
        border-bottom: $borderStyle;
        height: $tabHeaderHeight;
        padding: 28px 52px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include ultra-narrow-desktop {
            padding-inline: 10px 35px;
        }

        .SelectedValueText {
            display: none;
        }

        &_isValueSelected {
            height: 108px;

            .SelectedValueText {
                display: inline-block;
                color: black;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
            }
        }

        .TabHeaderText {
            font-family: $font-HyundaiSansHeadKR;
            font-weight: 400;
            font-size: 26px;
            line-height: 26px;
            color: var(--disabled-color);
            white-space: nowrap;
        }

        .TabChevron {
            display: block;
            background-image: $chevronIcon;
            background-repeat: no-repeat;
            width: 16px;
            height: 10px;
        }
    }

    &-TabHeaderTextWrapper {
        display: flex;
        flex-direction: column;

        @include mobile {
            width: 60%;
            height: 100%;
        }

        @include desktop {
            max-width: 90%;
        }

        .TabHeaderChevron {
            background-image: $thinChevronIcon;
            width: 10px;
            height: 16px;
            margin-inline-end: 8px;
            transform: scale(1.1);
        }
    }

    &-TabSubHeader {
        display: flex;
        gap: 10px;

        @include ultra-narrow-desktop {
            display: none;
        }
    }

    &-TabContent {
        display: none;
    }

    &-SelectWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .ProductStepsTab {
            &-SelectItem {
                width: 100%;
                min-height: 60px;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 18px 16px 18px 24px;
                margin-block-start: 16px;
                border: $borderStyle;
                color: black;
                background: white;
                cursor: pointer;
                text-align: start;

                @include ultra-narrow-desktop {
                    flex-direction: column;
                }

                &_active {
                    border: none;
                }

                .ConfigurableSelectPrice {
                    margin-inline-start: 12px;
                    color: var(--disabled-color);
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: center;

                    @include ultra-narrow-desktop {
                        margin-inline-start: 0;
                        margin-block-start: 12px;
                    }
                }

                &:hover, &_active {
                    background: $default-primary-base-color;
                    color: white;

                    .ConfigurableSelectPrice {
                        color: white;
                        font-weight: 700;
                    }
                }
            }
        }

        .Swatch {
            &-Header {
                @include ultra-narrow-desktop {
                    flex-direction: column;
                }
            }

            &-Value {
                @include desktop {
                    text-align: start;
                    width: auto;
                    max-width: 45%;
                }
            }
        }
    }

    &-Tab {
        @include mobile {
            display: none;
        }


        padding: 0;

        &_isClickable {
            cursor: pointer;
        }

        &_active {
            @include mobile {
                display: block;
                padding: 0;
            }

            @include desktop {
                padding: 40px 52px;
                border-bottom: $borderStyle;
                cursor: default;
            }

            .ProductStepsTab {
                &-TabHeaderTextWrapper {
                    @include mobile {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 0 16px;
                    }

                    .ChevronIcon {
                        margin-inline-end: 5px;
                    }
                }

                &-TabHeader {
                    margin-inline-start: 0;
                    margin-block-end: 0;
                    padding: 0;
                    border-bottom: none;

                    &_isValueSelected {
                        height: $tabHeaderHeight;
                    }

                    @include mobile {
                        position: fixed;
                        inset-block-start: var(--header-height);
                        z-index: 4;
                        background-color: white;
                        width: 100%;
                        height: calc(var(--breadcrumbs-height) - 3px);

                        .hideOnScroll & {
                            border-bottom: $borderStyle;
                        }
                    }

                    .SelectedValueText {
                        display: none;
                    }

                    .TabHeaderText {
                        @include desktop {
                            font-size: 40px;
                            line-height: 52px;
                            font-weight: 700;
                        }

                        @include mobile {
                            font-size: 26px;
                            line-height: 26px;
                            font-weight: 900;
                        }

                        color: black;
                    }

                    .PopupButton {
                        margin-inline-end: 16px;
                        height: 32px;
                        width: 123px;
                        border: solid 1px black;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 20px;
                        text-align: center;
                        color: black;
                    }

                    .TabChevron {
                        display: none;
                    }
                }

                &-TabContent {
                    display: block;

                    .ProductStepsTab-SelectWrapper {
                        @include mobile {
                            padding: 0 16px;
                        }
                    }
                }
            }
        }

        &_stepCode {
            &_OPTIONS_STEP, &_INSURANCE_STEP {
                @include desktop {
                    border-bottom: $borderStyle;
                    cursor: default;

                    .ProductStepsTab {
                        &-TabHeader {
                            border-bottom: none;

                            &_isValueSelected {
                                min-height: $tabHeaderHeight;
                                height: auto;
                                border-bottom: none;

                                .Price, .ItemPrice {
                                    color: black;
                                }

                                .RelatedProductTotals-TotalUpgrades {
                                    border-top: 1px solid #D9D9D9;
                                    padding-block-start: 10px;
                                }

                                .ProductStepsTab {
                                    &-TabHeaderTextWrapper {
                                        max-width: 100%;
                                        width: 80%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &-Wrapper {
        padding: 0 16px;

        &_isInterior {
            @include mobile {
                .ProductGallery {
                    margin: 0 calc(-#{$marginEndMobile}) $marginEndMobile;
                }
            }
        }
    }

    &-ColorsTab {
        @include mobile {
            .Tabs-Wrapper {
                padding: 0;
                margin-block-start: 70px;
            }

            .Swatch {
                margin-block-end: $marginEndMobile;
            }

            .ProductGallery {
                margin-block-end: $marginEndMobile;
            }

            .EstimatedDelivery {
                margin-block-end: $marginEndMobile;
            }
        }
    }
}

[dir=rtl] .ProductStepsTab-TabHeaderTextWrapper {
    .TabHeaderChevron {
        transform: scale(1.1) rotate(180deg);
    }
}
