/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';


.RelatedProductCard {
    &-FigureReview {
        .ProductCard {
            &-Picture {
                padding-block-end: 92%; // aspect ratio is (277 / 300) * 100%
            }
        }
    }

    &-Figure {
        position: relative;

        .Field {
            &-Wrapper {
                position: absolute;
                z-index: 2;
                inset-inline-start: 0;
                inset-block-start: 0;
            }

            &_type_checkbox {
                margin: 0;
                display: flex;
            }
        }
    }

    &-Description {
        margin-block-start: 8px;

        p {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            color: var(--disabled-color);
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            min-height: 40px;
        }
    }

    &-Content {
        .CardPriceWrapper {
            display: flex;

            & > span {
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                margin-inline-end: 3px;
                margin-block-start: 3px;
            }
        }

        .ProductCard {
            &-Name {
                margin-block-start: 4px;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                font-family: $font-HyundaiSansTextKR;
                text-transform: unset;
            }

            &-PriceWrapper {
                border-top: none;
                margin: 0;
                padding: 0;

                .ProductPrice {
                    overflow: visible;

                    &-PriceValue {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;
                        color: black;
                    }

                    &-TierPrice {
                        margin-block-end: 0;
                        display: flex;
                        width: max-content;
                        gap: 4px;
                        position: relative;
                    }
                }
            }
        }
    }
}
