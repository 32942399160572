/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.CategoryProductList {
    &-Page {
        &_layout {
            &_grid {
                gap: 32px;

                &:not(:first-child) {
                    margin-block-start: 32px;
                }

                @include desktop {
                    grid-template-columns: repeat(3, 1fr);
                }

                @include tablet {
                    grid-template-columns: repeat(2, 1fr);
                }

                @include mobile {
                    grid-template-columns: 1fr;
                    padding: 0 16px;
                }
            }
        }
    }
}
