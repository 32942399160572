/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

// JAID-114 Added ProductSummaryStep selector JAID-122 added DealersOfferStep selector

.ProductAttributes-Popup, .ProductSummaryStep, .DealersOfferStep-ProductAttributes {
    .Popup-ChildrenWrapper {
        @include desktop {
            min-width: 766px;
        }
    }

    .ProductAttributes {
        &-ProductHeader {
            display: flex;
            flex-direction: column;
            margin-block: 16px 32px;

            @include desktop {
                margin-block: 12px 40px;
                flex-direction: row;
                align-items: center;
            }

            .Image {
                max-width: 382px;
                padding-block-end: 58.54%;

                @include desktop {
                    max-width: 289px;
                    padding-block-end: 26.8%;
                }
            }
        }

        &-ProductTitle {
            font-family: $font-HyundaiSansHeadKR;
            font-size: 22px;
            line-height: 28px;
            font-weight: 700;
            width: 100%;

            @include desktop {
                font-size: 30px;
                line-height: 32px;
            }
        }

        &-TitleContainer {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            @include desktop {
                margin-inline-start: 48px;
            }
        }

        &-ProductTrim {
            font-family: $font-HyundaiSansHeadKR;
            font-weight: 700;

            @include desktop {
                font-size: 22px;
                line-height: 28px;
            }

            @include mobile {
                font-size: 16px;
                line-height: 24px;
                margin-block-start: 10px;
            }
        }

        &-ProductTotalAmount {
            justify-content: space-between;
            border-block-start: 3px solid var(--body-content-color);
            padding-block-start: 32px;
            padding-block-end: 44px;
            font-family: $font-HyundaiSansHeadKR;

            @include desktop {
                display: flex;
            }

            .ProductTotalAmount {
                &-Text {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;

                    @include desktop {
                        font-size: 22px;
                        line-height: 28px;
                    }
                }

                &-Value {
                    font-weight: 700;
                    font-size: 26px;
                    line-height: 32px;
                    text-align: end;

                    @include desktop {
                        font-size: 40px;
                        line-height: 52px;
                    }
                }

                &-SecondaryValue {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    font-family: $font-HyundaiSansTextKR;
                    text-align: end;
                }

                &-Text,
                &-Value {
                    display: block;
                }
            }
        }

        &-Group {
            font-weight: 700;
            padding: 0;
            margin-block-end: 20px;

            @include desktop {
                font-size: 22px;
                line-height: 28px;
            }

            @include mobile {
                font-size: 16px;
                line-height: 24px;
            }
        }

        &-AttributeHeader {
            display: flex;
            justify-content: space-between;
        }

        &-AttributeHeaderPrice {
            font-family: $font-HyundaiSansHeadKR;
            color: black;
            font-weight: 700;

            @include desktop {
                font-size: 22px;
                line-height: 28px;
            }

            @include mobile {
                font-size: 16px;
                line-height: 24px;
            }
        }

        &-AttributeBlock {
            padding-block-end: 20px;

            &:not(:first-child) {
                border-block-start: 1px dashed var(--disabled-color);
            }

            @include desktop {
                &:not(:first-child) {
                    padding: 32px 0;
                }
            }

            @include mobile {
                &:not(:first-child) {
                    padding: 32px 0 20px 0;
                    margin-block-start: 0;
                }
            }
        }

        &-Content {
            .ProductAttributes-AttributeBlock_isSummaryFirstAttributeBlock {
                border-block-start: none;
                padding-block-start: 0;
            }
        }

        &-AttributeLabel,
        &-ValueLabel {
            border: none;
            padding: 0;
            font-size: 16px;
            line-height: 24px;
        }

        &-AttributeLabel {
            font-weight: 400;
            display: list-item;
            list-style-type: disc;
            list-style-position: inside;

            @include desktop {
                margin-block-end: 8px;
            }

            @include mobile {
                padding-inline-start: 6px;
            }
        }

        &-ValueLabel {
            font-weight: 700;
            margin-block-end: 12px;

            @include mobile {
                margin-inline-start: 22px;
            }

            @include desktop {
                text-align: end;
                margin-block-end: 8px;
            }
        }

        &-Attributes {
            @include mobile {
                grid-template-columns: 1fr;
            }
        }

        &-VehicleIcon {
            background-image: $vehicleIcon;
            width: 90px;
            height: 39px;
            margin-block-end: 28px;

            @include mobile {
                transform-origin: top left;
                transform: scale(0.78);
                margin-block-end: 11px;
            }
        }

        &-ThankYou {
            font-family: $font-HyundaiSansHeadKR;
            font-size: 50px;
            font-weight: 700;
            line-height: 52px;
            color: $black;
            margin-block-end: 32px;

            span {
                display: block;
            }

            @include mobile {
                font-size: 26px;
                font-weight: 700;
                line-height: 32px;
                width: 100%;
            }
        }

        &-Note {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: $black;
            padding-block-end: 20px;
            border-block-end: 3px solid var(--body-content-color);
            margin-block-end: 32px;
        }

        &-ExpandableContentButton {
            display: flex;
        }
    }
}

// Styling stuff only for SummaryStep

.ProductSummaryStep {
    .ProductAttributes {
        &-ValueLabel {
            @include desktop {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
            }
        }
    }
}
