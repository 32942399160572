/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

$topPadding: 40px;
$addToCartHeight: 70px;
$contentHeight: calc(100vh - var(--header-height) - var(--breadcrumbs-height) - #{$addToCartHeight} - #{$topPadding});
$galleryMaxHeight: 430px;
$galleryInteriorHeight: 644px;
$paddingLeftRight: 40px;

[dir="rtl"] .ProductMainViewColors {
    &-Gallery {
        &_isInterior {
            @include desktop {
                transform: translateX(50%);
            }
        }
    }
}

.ProductMainViewColors {
    grid-column: 1/3;
    padding: 0 $paddingLeftRight;

    &-Gallery {
        .ProductGallery {
            max-height: $galleryMaxHeight;
        }

        &_isInterior {
            position: absolute;
            inset-block-start: calc( -#{$topPadding} );
            width: calc(100vw - #{$leftMenuWidth});
            inset-inline-start: calc( -#{$paddingLeftRight} );
            
            @include desktop {
                inset-inline-start: 50%;
                transform: translateX(-50%);
            }

            .ProductGallery {
                margin: 0;

                &-Slider {
                    height: $galleryInteriorHeight;
                }
            }

            .Image-Image {
                position: relative;
                inset-block-start: 0;
                width: 100%;
                height: 100%;
                object-position: 50% 50%;
                object-fit: cover;
            }
        }

        margin-block-end: 20px;
    }

    &-InformText {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--disabled-color);
        margin-block-end: 20px;

        &_isInterior {
            position: absolute;
            inset-block-start: calc(var(--gallery-interior-slider-height) - (2 * #{$topPadding} + 20px))
        }
    }

    .ContentWrapper {
        padding: 0;
    }
}
