/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.ProductListPage {
    &.CategoryProductList {
        &-Page {
            &_layout {
                &_grid {
                    gap: 32px;

                    @include mobile {
                        grid-template-columns: 1fr;
                        padding: 0 16px;
                    }

                    @include desktop {
                        grid-template-columns: repeat(3, 1fr);
                    }

                    @include super-wide-desktop {
                        max-width: 1640px;
                        grid-template-columns: repeat(4, 1fr);
                        margin-inline: auto;
                    }
                }
            }
        }
    }
}
