/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.RelatedProductTotals {
    cursor: default;

    @include desktop {
        margin-block-start: 10px;
        width: 100%;
    }

    &-Items {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid var(--separator-text-color);

        @include desktop {
            padding: 12px 0 20px 0;
        }

        @include mobile {
            padding: 0;
        }

        &_isCompact {
            border-bottom: none;
        }
    }

    &-Item {
        display: flex;
        justify-content: space-between;
        margin-block-start: 8px;

        @include desktop {
            gap: 20px;
        }

        .RelatedProductTotals {
            &-Name {
                .ItemName {
                    font-size: 14px;
                    line-height: 20px;
                    color: black;

                    @include desktop {
                        font-weight: 700;
                    }

                    @include mobile {
                        font-weight: 400;
                    }
                }
            }

            &-Price {
                display: flex;

                @include desktop {
                    align-items: center;
                }

                .ItemPrice {
                    font-size: 14px;
                    line-height: 20px;
                    margin-inline-end: 8px;

                    @include desktop {
                        color: var(--secondary-orange-color);
                        font-weight: 700;
                        min-width: 90px;
                        text-align: end;
                    }

                    @include mobile {
                        color: var(--disabled-color);
                        font-weight: 400;
                    }
                }
            }
        }
    }

    &-DeleteBtn {
        margin-block-start: 1px;
        cursor: pointer;
    }

    &-ItemsCount {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-block-end: 16px;
        border-bottom: 3px solid black;

        @include ultra-narrow-desktop {
            flex-direction: column;
            align-items: flex-start;
        }

        .Count {
            color: var(--separator-text-color);
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            span {
                font-weight: 700;
                color: black;
            }
        }

        .ClearAll {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: black;
                margin-inline-start: 8px;
            }
        }
    }

    &-Category {
        display: flex;
        flex-direction: column;
        padding-block-start: 20px;
        padding-block-end: 20px;

        .CategoryLabel {
            font-family: $font-HyundaiSansHeadKR;
            font-weight: 700;
            color: black;
            font-size: 16px;
            line-height: 24px;
            margin-block-end: 16px;
        }

        &:not(:first-child) {
            border-top: 1px dashed var(--disabled-color);
        }
    }

    &-TotalsWrapper {
        padding-block-start: 22px;
    }

    &-TotalUpgrades {
        display: flex;
        justify-content: space-between;

        @include ultra-narrow-desktop {
            flex-direction: column;
        }

        .Label {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: black;

            @include mobile {
                font-family: $font-HyundaiSansHeadKR;
            }
        }

        .Price {
            font-weight: 700;
            font-size: 22px;
            line-height: 28px;
            color: var(--secondary-orange-color);
        }
    }
}
